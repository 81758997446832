<template>
  <div>
    <Row :gutter="8" class="m-b-5">
      <i-col :xs="24" :sm="12" :md="4" :lg="4">
        <Select v-model="form" size="small" placeholder="订单来源">
          <Option
            v-for="(item, index) in orderForm"
            :key="index"
            :value="item.id"
            >{{ item.name }}</Option
          >
        </Select>
      </i-col>
      <i-col :xs="24" :sm="12" :md="4" :lg="4" v-if="form === 1">
        <Select size="small" v-model="query.orderStatus" placeholder="状态" clearable>
          <Option  v-for="(status,index) in statusArray" :key="index"
            :value="status.id">{{status.name}}</Option>
        </Select>
      </i-col>
      <i-col :xs="24" :sm="12" :md="4" :lg="4">
        <i-input
          size="small"
          v-model="query.keyword"
          placeholder="关键字"
        ></i-input>
      </i-col>
      <i-col :xs="24" :sm="12" :md="4" :lg="4" class="m-b-5">
        <Button
          size="small"
          type="primary"
          icon="ios-search"
          @click="SearchData"
          >搜索</Button
        >
        <Button size="small" type="success"  v-if="form === 1" class="m-l-5" @click="showRefundRecords">查看退款记录</Button>
      </i-col>
    </Row>
    <Table
      size="small"
      :columns="form===1?columnManagedata:columndata"
      :data="tableData"
      stripe
    ></Table>
    <div class="paging_style">
      <Page
        size="small"
        :total="total"
        :page-size="query.pageSize"
        show-total
        show-elevator
        :current="query.pageNumber"
        @on-change="changePage"
      ></Page>
    </div>
    <purchase-resource-list ref="purchaseResourceList"/>

    <OrderDetail ref="orderDetail" />
    <Detail ref="mediaOrderDetail" />
    <refundModal ref="refundModal" @on-success="initPageData"></refundModal>
    <refundRecordsModal ref="refundRecordsModal" @on-success="initPageData"></refundRecordsModal>
     <!-- 弹窗 合同详情 -->
     <Modal v-model="modalContract" :width="1200" footer-hide>
        <contract-detail v-if="modalContract" :id="contractId"  :versionId="contractId" ></contract-detail>
      </Modal>
       <!-- 弹窗 退款记录 -->
      <Modal v-model="refundModal" width="1200" title="退款记录" :footer-hide="true">
     <Table size="small" stripe :data="refundRecords" :columns="refundColumns"></Table>
  </Modal>

  </div>
</template>

<script>
import purchaseResourceList from '@/components/order/common/PurchaseResourceList'
import OrderDetail from '@/components/order/common/ModalOrderDetail'
import Detail from './Detail'
import refundModal from './refund'
import refundRecordsModal from './refundrecord'
import ContractDetail from '@/components/contract/contractDetail/Contract'
import { getMerchantOrderPage, getRefundPage, virtualTicketNo } from '@/api/mediamall/order'
import { getMediaOrderPage, updateRemark, deleteMediaOrder } from '@/api/order/cloudorder'
import { signurlByAttach } from '@/api/resource/endpoint'
import { formatOrderStatus } from '@/utils/tagStatus'
import { GetCurrentSchedule } from '@/utils/dateFormat'
import { toMoney } from '@/utils/wnumb_own'
import { downloadFileRequest } from '@/utils/download'

export default {
  components: { purchaseResourceList, OrderDetail, Detail, ContractDetail, refundModal, refundRecordsModal },
  data () {
    return {
      orderForm: [
        { id: 1, name: '商城直采' }, // 源于有赞商城
        { id: 2, name: '商城渠道' } // 源于渠道商和渠道介绍业主直签字的类型，由销售人员在Union端创建的广告商城类型的订单
      ],
      form: 1,
      total: 0,
      statusArray: [],
      query: {
        pageNumber: 1,
        pageSize: 15,
        publisherId: this.$store.getters.token.userInfo.publisherId,
        keyword: ''
      },
      tableData: [],
      modalContract: false,
      contractId: 0,
      columndata: [
        { title: '编号', key: 'code', width: 145 },
        { title: '客户名称', key: 'advertiserName' },
        { title: '投放品牌', key: 'brandName', width: 120 },
        { title: '发布类型', key: 'typeName', width: 80 },
        // {
        //   title: '投放品牌',
        //   render: (h, params) => {
        //     if (params.row.type === 2) {
        //       return h('strong', { attrs: { style: 'color:green;' } }, params.row.typeName)
        //     } else if (params.row.type === 3) {
        //       return h('strong', { attrs: { style: 'color:#19be6b;' } }, params.row.typeName)
        //     } else {
        //       return h('span', params.row.brandName)
        //     }
        //   }
        // },
        { title: '备注', key: 'remark' },
        // {
        //   title: '投放档期',
        //   key: 'startDate',
        //   sortable: 'custom',
        //   render: (h, params) => {
        //     return h('span', GetCurrentSchedule(params.row.startDate, params.row.endDate))
        //   }
        // },
        {
          title: '投放档期',
          render: (h, data) => {
            const spans = []
            data.row.purchasedScheduleList.forEach(item => {
              spans.push(h('span', GetCurrentSchedule(item.startDate, item.endDate)))
              spans.push(h('br'))
            })
            return h('div', spans)
          }
        },
        // { title: '档期天数', key: 'tradePublishday', width: 90, align: 'center' },
        {
          title: '采购资源统计',
          width: 120,
          render: (h, params) => {
            const spans = []
            params.row.orderProductQuantityBeanList.forEach(resource => {
              spans.push(h(
                'span',
                resource.productName
              ))
              spans.push(h(
                'span',
                {
                  attrs: {
                    class: 'text-orange text-12'
                  }
                },
                resource.quantity
              ))
              spans.push(h(
                'span',
                resource.unitName
              ))
              spans.push(h('br'))
            })
            return h('div', {
              attrs: {
                class: 'text-href'
              },
              on: {
                click: () => {
                  this.$refs.purchaseResourceList.showProductSkuList(params.row.id)
                }
              }
            }, spans)
          }
        },
        {
          title: '媒体发布费刊例',
          key: 'listUsePrice',
          align: 'center',
          render: (h, params) => {
            return h('div', [
              h(
                'span',
                {
                  class: 'money'
                },
                toMoney(params.row.listUsePrice)
              )
            ])
          }
        },
        {
          title: '执行价',
          key: 'signUsePrice',
          minWidth: 60,
          align: 'center',
          render: (h, params) => {
            return h('div', [
              h(
                'span',
                {
                  class: 'money'
                },
                toMoney(params.row.signUsePrice)
              )
            ])
          }
        },

        // 制作费

        // 上下刊费

        {
          title: '服务费刊例',
          key: 'listServicePrice',
          align: 'center',
          render: (h, params) => {
            return h('div', [
              h(
                'span',
                {
                  class: 'money'
                },
                toMoney(params.row.listServicePrice)
              )
            ])
          }
        },
        {
          title: '服务费',
          key: 'signServicePrice',
          align: 'center',
          render: (h, params) => {
            return h('div', [
              h(
                'span',
                {
                  class: 'money'
                },
                toMoney(params.row.signServicePrice)
              )
            ])
          }
        },
        {
          title: '合同总额',
          key: 'signPrice',
          align: 'center',
          render: (h, params) => {
            return h('div', [
              h(
                'span',
                {
                  class: 'money'
                },
                toMoney(params.row.signPrice)
              )
            ])
          }
        },

        {
          title: '状态',
          align: 'center',
          width: 110,
          render: (h, params) => {
            return formatOrderStatus(h, params.row.status, params.row.statusName)
          }
        },
        {
          title: '操作',
          align: 'center',
          render: (h, params) => {
            let editButton = ''
            const detailButton = h('a',
              {
                style: {
                  marginRight: '5px'
                },
                on: {
                  click: () => {
                    this.$refs.orderDetail.init(params.row.id)
                    // this.handleShowDetail(params.row.id)
                  }
                }
              }, '订单详情'
            )

            const contractdetailButton = h('a',
              {
                style: {
                  marginRight: '5px'
                },
                on: {
                  click: () => {
                    this.contractId = params.row.contractId
                    this.modalContract = true
                    // this.$refs.orderDetail.init(params.row.id)
                    // this.handleShowDetail(params.row.id)
                  }
                }
              }, '合同详情'
            )
            editButton = h(
              'a',
              {
                style: {
                  marginRight: '5px'
                },
                on: {
                  click: () => {
                    let mark = params.row.remark
                    this.$Modal.confirm({
                      title: '修改备注',
                      render: (h) => {
                        return h('Input', {
                          props: {
                            value: mark,
                            autofocus: true,
                            placeholder: '请填写新的的备注信息'
                          },
                          on: {
                            input: (val) => {
                              mark = val
                            }
                          }
                        })
                      },
                      loading: true,
                      onOk: () => {
                        updateRemark({
                          remark: mark,
                          mediaOrderId: params.row.mediaOrderId
                        }).then((res) => {
                          if (res && !res.errcode) {
                            this.$Notice.success({ desc: '编辑备注成功！' })
                            this.initPageData()
                          }
                          this.$Modal.remove()
                        })
                          .catch((error) => {
                            this.$Modal.remove()
                            this.$Notice.error({
                              title: '服务器请求错误',
                              desc: error
                            })
                          })
                      }
                    })

                    // this.currentcheckBrand = params.row
                    // this.showapprovalHistoryModal = true
                  }
                }
              }, '编辑备注'
            )
            const deleteButton = h(
              'a',
              {
                style: {
                  marginRight: '5px',
                  color: '#ef4f4f'
                },
                on: {
                  click: () => {
                    this.$Modal.confirm({
                      title: '确定删除？',
                      content: '<p>确定要删除该条数据，删除后将不可恢复！</p>',
                      loading: true,
                      onOk: () => {
                        deleteMediaOrder({
                          mediaOrderId: params.row.mediaOrderId
                        }).then((res) => {
                          if (res && !res.errcode) {
                            this.$Notice.success({ desc: '删除订单成功！' })
                            this.initPageData()
                          }
                          this.$Modal.remove()
                        })
                          .catch((error) => {
                            this.$Modal.remove()
                            this.$Notice.error({
                              title: '服务器请求错误',
                              desc: error
                            })
                          })
                      }
                    })
                  }
                }
              }, '删除'
            )

            return h('div', [
              detailButton, contractdetailButton, editButton, [0, -1, 5].includes(params.row.status) ? deleteButton : null
            ])
          }
        }
      ],

      refundModal: false,
      refundRecords: [],
      refundColumns: [
        {
          title: '退款金额',
          key: 'refundFee',
          minWidth: 60,
          align: 'center',
          render: (h, params) => {
            return h('div', [
              h(
                'span',
                {
                  class: 'money'
                },
                toMoney(params.row.refundFee)
              )
            ])
          }
        },

        {
          title: '退款说明',
          minWidth: 120,
          key: 'remark'
        },
        {
          title: '退款说明材料',
          key: 'freePeriod',
          render: (h, params) => {
            const result = []
            JSON.parse(params.row.attach).forEach((item, index) => {
              result.push(
                h('p', [h(
                  'a',
                  {
                    style: {
                      marginRight: '5px'
                    },
                    on: {
                      click: () => {
                        signurlByAttach({ attachId: item.id }).then(res => {
                          downloadFileRequest(res.data, { }, item.fileName || ('文件' + index))
                        })

                        // downloadFileRequest(item.value, {}, item.fileName)
                      }
                    }
                  },
                  item.fileName || ('说明材料' + (index + 1))
                )])
              )
            })
            return h('div', result)
          }
        },
        { title: '退款时间', key: 'createTime' },

        { title: '退款操作人', key: 'userName' },

        {
          title: '退款状态',
          key: 'refundStateName'
          // render: (h, data) => {
          //   return h('span', GetCurrentSchedule(data.row.startDate, data.row.endDate))
          // }
        }
      ],

      columnManagedata: [
        {
          title: '订单编号',
          key: 'orderNo'
        },
        {
          title: '商品名称',
          key: 'title'
        },
        { title: '数量', key: 'num' },
        {
          title: '商品金额',
          key: 'totalFee',
          render: (h, params) => {
            return h('div', [
              h(
                'span',
                {
                  class: 'money'
                },
                toMoney(params.row.totalFee)
              )
            ])
          }
        },
        {
          title: '实付金额',
          key: 'totalAmount',
          render: (h, params) => {
            return h('div', [
              h(
                'span',
                {
                  class: 'money'
                },
                toMoney(params.row.totalAmount)
              )
            ])
          }
        },
        { title: '支付方式', key: 'payTypeName' },

        {
          title: '分销员信息',
          key: 'salesmanName',
          render: (h, params) => {
            return h('div', [
              h(
                'span',
                (params.row.salesmanName || ' - ') + (params.row.salesmanMobile ? ('(' + params.row.salesmanMobile +
                ')') : '')
              )
            ])
          }
        },
        { title: '状态', key: 'statusName' },
        { title: '退款状态', key: 'localStatusName' },
        { title: '创建时间', key: 'createTime' },
        {
          title: '操作',
          align: 'center',
          width: 120,
          render: (h, params) => {
            const that = this
            const detailButton = h('a',
              {
                style: {
                  marginRight: '5px'
                },
                on: {
                  click: () => {
                    that.$refs.mediaOrderDetail.showModal(params.row.orderNo)
                    // this.handleShowDetail(params.row.id)
                  }
                }
              }, '详情'
            )

            const verifyButton = h('a',
              {
                style: {
                  marginRight: '5px'
                },
                on: {
                  click: () => {
                    that.$Modal.confirm({
                      title: '操作确认？',
                      content:
                        '<p>确定要对订单【 ' +
                        params.row.orderNo +
                        '】为进行核销？</p>',
                      loading: true,
                      onOk: () => {
                        virtualTicketNo({
                          orderNo: params.row.orderNo
                        }).then((res) => {
                          if (res && !res.errcode) {
                            that.$Notice.success({ desc: '订单核销成功！' })
                          }
                          that.initPageData()
                          that.$Modal.remove()
                        })
                          .catch((error) => {
                            that.$Modal.remove()
                            that.$Notice.error({
                              title: '服务器请求错误',
                              desc: error
                            })
                          })
                      }
                    })
                  }
                }
              }, '核销'
            )

            const refundButton = h('a',
              {
                style: {
                  marginRight: '5px',
                  color: '#ef4f4f'
                },
                on: {
                  click: () => {
                    that.$refs.refundModal.showModal(params.row)
                  //   that.$Modal.confirm({
                  //     title: '操作确认？',
                  //     content:
                  //       '<p>当前订单退款手续费为【 ' +
                  //         toMoney(params.row.serviceCharge) +
                  //       '】</p><p>实际退款金额为【 ' +
                  //         toMoney(params.row.maxRefundFee) +
                  //       '】</p><p>确定要对订单【 ' +
                  //       params.row.orderNo +
                  //       '】为进行退款？</p>',
                  //     loading: true,
                  //     onOk: () => {
                  //       orderRefund({
                  //         orderNo: params.row.orderNo,
                  //         refundFee: params.row.totalAmount
                  //       }).then((res) => {
                  //         if (res && !res.errcode) {
                  //           that.$Notice.success({ desc: '订单退款成功！' })
                  //         }
                  //         that.initPageData()
                  //         that.$Modal.remove()
                  //       })
                  //         .catch((error) => {
                  //           that.$Modal.remove()
                  //           that.$Notice.error({
                  //             title: '服务器请求错误',
                  //             desc: error
                  //           })
                  //         })
                  //     }
                  //   })
                  }
                }
              }, '退款'
            )
            const refundRecord = h('a',
              {
                style: {
                  marginRight: '5px'
                },
                on: {
                  click: () => {
                    getRefundPage({ keyword: params.row.orderNo, pageNumber: 1, pageSize: 50 }).then(res => {
                      that.refundRecords = res.list
                      that.refundModal = true
                    })
                  }
                }
              }, '退款记录'
            )
            return h('div', [
              [1].includes(params.row.verifyStatus) ? verifyButton : null, [10, -10].includes(params.row.localStatus) ? refundButton : null, params.row.localStatus === 30 ? refundRecord : null, detailButton
            ])
          }
        }
      ]
    }
  },
  created () {
    this.reloadStatus()
    this.initPageData()
  },
  methods: {
    initPageData () {
      if (this.form === 1) {
        getMerchantOrderPage(this.query).then((response) => {
          this.total = response.totalRow
          this.tableData = response.list
          this.query.pageNumber = response.pageNumber
        })
      } else if (this.form === 2) {
        getMediaOrderPage(this.query).then((response) => {
          this.total = response.totalRow
          this.tableData = response.list
          this.query.pageNumber = response.pageNumber
        })
      }
    },
    showRefundRecords () {
      this.$refs.refundRecordsModal.showModal()
    },
    changePage (currentPage) {
      this.query.pageNumber = currentPage
      this.initPageData()
    },
    SearchData () {
      this.query.pageNumber = 1
      this.initPageData()
    },
    reloadStatus () {
      if (this.form === 1) {
        this.statusArray = [
          { id: 10, name: '待支付' },
          { id: 20, name: '已支付' },
          { id: 30, name: '待接单' },
          { id: 40, name: '待发货' },
          { id: 50, name: '已发货' },
          { id: 60, name: '已完成' },
          { id: 70, name: '已关闭' }
        ]
      } else if (this.form === 2) {
      }
    }
  },
  watch: {
    form () {
      this.reloadStatus()
      this.query.pageNumber = 1
      this.tableData = []
      this.initPageData()
    }
  },
  computed: {}
}
</script>
