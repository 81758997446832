<template>
  <div>
  <Modal  v-model="refundModal" width="1200" title="退款记录" :footer-hide="true">
    <Row :gutter="8" class="m-b-5">

      <i-col :xs="24" :sm="12" :md="4" :lg="4">
        <DatePicker size="small" transfer :show-week-numbers="true" type="daterange" v-model="orderSchdule"
          @on-change="scheduleChange" style="width: 100%" placement="top-start" placeholder="退款时间"></DatePicker>
      </i-col>
      <i-col :xs="24" :sm="12" :md="4" :lg="4">
        <i-input
          size="small"
          v-model="query.keyword"
          placeholder="关键字"
        ></i-input>
      </i-col>
      <i-col :xs="24" :sm="12" :md="4" :lg="4" class="m-b-5">
        <Button
          size="small"
          type="primary"
          icon="ios-search"
          @click="SearchData"
          >搜索</Button
        >

        <i-button type="primary" class="m-l-5" size="small"  @click="handleDownload">下载</i-button>
      </i-col>
    </Row>
    <Table size="small" stripe :data="refundRecords" :columns="refundColumns"></Table>
    <div class="paging_style">
      <Page
        size="small"
        :total="total"
        :page-size="query.pageSize"
        show-total
        show-elevator
        :current="query.pageNumber"
        @on-change="changePage"
      ></Page>
    </div>
  </Modal>
  <Detail ref="mediaOrderDetail" />
</div>
</template>

<script>
import { downloadFileRequest } from '@/utils/download'
import Detail from './Detail'

import { getRefundPage } from '@/api/mediamall/order'
import { signurlByAttach } from '@/api/resource/endpoint'
import { toMoney } from '@/utils/wnumb_own'
import { GetDateStr } from '@/utils/dateFormat'
export default {
  components: { Detail },
  props: {
  },
  data () {
    return {
      refundModal: false,
      orderSchdule: [],
      query: {
        pageNumber: 1,
        pageSize: 15,
        keyword: '',
        startDate: '',
        endDate: ''
      },
      total: 0,
      refundRecords: [],
      refundColumns: [
        {
          title: '订单编号',
          key: 'orderNo',
          render: (h, params) => {
            return h('a',
              {
                on: {
                  click: () => {
                    this.$refs.mediaOrderDetail.showModal(params.row.orderNo)
                  }
                }
              }, params.row.orderNo)
          }
        },
        {
          title: '退款金额',
          key: 'refundFee',
          minWidth: 60,
          align: 'center',
          render: (h, params) => {
            return h('div', [
              h(
                'span',
                {
                  class: 'money'
                },
                toMoney(params.row.refundFee)
              )
            ])
          }
        },

        {
          title: '退款说明',
          minWidth: 120,
          key: 'remark'
        },
        {
          title: '退款说明材料',
          key: 'freePeriod',
          render: (h, params) => {
            const result = []
            JSON.parse(params.row.attach).forEach((item, index) => {
              result.push(
                h('p', [h(
                  'a',
                  {
                    style: {
                      marginRight: '5px'
                    },
                    on: {
                      click: () => {
                        signurlByAttach({ attachId: item.id }).then(res => {
                          downloadFileRequest(res.data, { }, item.fileName || ('文件' + index))
                        })

                        // downloadFileRequest(item.value, {}, item.fileName)
                      }
                    }
                  },
                  item.fileName || ('说明材料' + (index + 1))
                )])
              )
            })
            return h('div', result)
          }
        },
        { title: '退款时间', key: 'createTime' },

        { title: '退款操作人', key: 'userName' },

        {
          title: '退款状态',
          key: 'refundStateName'
          // render: (h, data) => {
          //   return h('span', GetCurrentSchedule(data.row.startDate, data.row.endDate))
          // }
        }
      ]

    }
  },
  mounted () {},
  methods: {

    formatMoney (number) {
      return toMoney(number)
    },
    showModal () {
      this.initPageData()
      this.refundModal = true
    },

    hideModal () {
      this.refundModal = false
    },

    scheduleChange () {
      const shcedule = this.orderSchdule
      if (shcedule[0] === '' || shcedule[1] === '') {
        this.query.startDate = ''
        this.query.endDate = ''
      } else {
        this.query.startDate = GetDateStr(shcedule[0], 1)
        this.query.endDate = GetDateStr(shcedule[1], 1)
      }
    },
    initPageData () {
      getRefundPage(this.query).then((response) => {
        this.total = response.totalRow
        this.refundRecords = response.list
        this.query.pageNumber = response.pageNumber
      })
    },

    changePage (currentPage) {
      this.query.pageNumber = currentPage
      this.initPageData()
    },
    SearchData () {
      this.query.pageNumber = 1
      this.initPageData()
    },

    handleDownload () {
      const filename = '广告商城退款记录.xlsx'
      const query = {
        keyword: this.query.keyword,
        startDate: this.query.startDate,
        endDate: this.query.endDate
      }

      downloadFileRequest(process.env.VUE_APP_API_URL_V2 + '/ooh-life/mediaMall/refund/exportRefundExcel', query, filename)
    }
  },
  watch: {},
  computed: {
  }
}
</script>
